import {
  DatasetEntity,
  JobDatasetType,
  JobInputName,
  JobRun,
} from '@biostrand/biostrandapi/javascript/dist/BFFProjectsApi';
import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {CloseNotificationButton, enqueueSnackbarNotification, uApi} from '@biostrand/components';
import {COLUMN_NAMES} from '@biostrand/components/src/datasets/datasetGrid/datasetColumns';
import {DatasetsList} from '@biostrand/components/src/datasets/datasetGrid/DatasetsList';
import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactJson from '@microlink/react-json-view';
import {Button, Chip, IconButton, LinearProgress, Stack, Typography} from '@mui/material';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getStatusColor} from './getStatusColor';
import {InfoField} from './InfoField';
import {jobTypesSelector} from './jobsSelectors';
import dayjs from 'dayjs';
import {ProtoJobType} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";

interface Props {
  jobRunId: string;
  popupKey: string;
}

const JobRunInfoPopup = (props: Props): JSX.Element => {
  const {jobRunId, popupKey} = props;
  const [jobRunInfo, setJobRunInfo] = useState<JobRun | undefined>();
  const [datasets, setDatasets] = useState<DatasetEntity[] | undefined>();
  const [jobRunItem, setJobRunItem] = useState<JobsHistoryItem | undefined>();
  const [isLoading, setIsloading] = useState(true);
  const [inputDatasets, setInputDatasets] = useState<DatasetManagerDatasetEntity[]|undefined>();
  const [outputDatasets, setOutputDatasets] = useState<DatasetManagerDatasetEntity[]|undefined>();

  const jobTypes = useSelector(jobTypesSelector);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    const runQ = async () => {
      setIsloading(true);
      const result = await uApi.getBFFProjectsApi().getJobRun(jobRunId);
      setJobRunInfo(result.data?.jobrun);
      setDatasets(result.data?.datasets);
    };

    runQ();
  }, [jobRunId]);

  useEffect(() => {
    if (datasets && datasets.length && jobRunInfo && jobRunInfo.job_datasets) {
      const dsInputNames = jobRunInfo.job_datasets?.filter(jd => jd.dataset_type === JobDatasetType.INPUT) || [];
      const dsOutputNames = jobRunInfo.job_datasets?.filter(jd => jd.dataset_type === JobDatasetType.OUTPUT) || [];

      const inputDatasets: DatasetManagerDatasetEntity[] = dsInputNames.map(inputDs => {
        return datasets.find(ds => ds.id === inputDs.dataset_id) as DatasetManagerDatasetEntity;
      });

      const outputDatasets: DatasetManagerDatasetEntity[] = dsOutputNames.map(outputDs => {
        return datasets.find(ds => ds.id === outputDs.dataset_id) as DatasetManagerDatasetEntity;
      });

      setInputDatasets(inputDatasets);
      setOutputDatasets(outputDatasets);
    }
  }, [jobRunInfo, datasets]);

  useEffect(() => {
    if (jobRunInfo && jobTypes) {
      setJobRunItem({
        ...jobRunInfo,
        jobType: jobTypes.find(jr => jr.id === jobRunInfo.job_type_id),
      });

      setIsloading(false);
    }
  }, [jobTypes, jobRunInfo]);

  const onClose = () => {
    dispatch(closePopup(popupKey));
  };

  const onCopyIdToClipboard = async (value: string): Promise<void> => {
    try {
      await copy(value);

      dispatch(
        enqueueSnackbarNotification({
          message: 'Job id copied to clipboard',
          key: 'success-key',
          options: {
            variant: 'success',
            persist: false,
            action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
          },
        }),
      );
    } catch (error) {
    }
  };

  const getInput = (jri?: JobsHistoryItem) => {
    if (jri && jri.inputs) {
      const inputsItem = jri.inputs.find(item => item.name === JobInputName.INPUTS);
      if (inputsItem) {
        try {
          const inpAsString = atob(inputsItem.payload)
            .split('\n')
            .join('');
          return JSON.parse(inpAsString);
        } catch (e) {
          return ' aaaaa ';
        }
      }
    }
  };

  const inputs = getInput(jobRunItem);

  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{flex: 1, pt: 1, pb: 1, position: 'relative', overflow: 'hidden', minWidth: 400, maxWidth: 1000}}
    >
      <Stack sx={{flex: 1, overflowY: 'scroll'}} spacing={1}>
        {isLoading && <LinearProgress/>}
        {jobRunItem && (
          <InfoField
            name={t('Job type')}
            value={
              <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                <div>{jobRunItem.jobType?.name}</div>
                <IconButton
                  color="primary"
                  title={'Copy job id'}
                  size={'small'}
                  onClick={(): Promise<void> => onCopyIdToClipboard(jobRunItem.id || '')}
                >
                  <FontAwesomeIcon icon={faCopy} size="sm"/>
                </IconButton>
              </Stack>
            }
          />
        )}

        {jobRunItem?.jobType?.description && (
          <InfoField name={t('Job description')} value={jobRunItem.jobType?.description}/>
        )}

        {jobRunItem ? (
          <InfoField
            name={t('Job status')}
            value={
              <Chip
                color={getStatusColor(jobRunItem.status)}
                title={jobRunItem.failure_reason}
                label={jobRunItem.status}
                variant={'outlined'}
                size={'small'}
              />
            }
          />
        ) : null}

        {jobRunItem && jobRunItem.failure_reason && (
          <InfoField name={t('Failure reason')} value={jobRunItem.failure_reason}/>
        )}
        {jobRunItem && jobRunItem.last_updated_on && (
          <InfoField
            name={t('Updated date')}
            value={dayjs(jobRunItem.last_updated_on).format('MMMM DD, YYYY, hh:mm')}
          />
        )}
        {jobRunItem && jobRunItem.created_on && (
          <InfoField name={t('Created date')} value={dayjs(jobRunItem.created_on).format('MMMM DD, YYYY, hh:mm')}/>
        )}

        {inputs && (
          <>
            <Typography variant={'subtitle1'}>{t('Inputs')}</Typography>
            <InfoField
              name={t('Updated date')}
              value={
                <Stack sx={{maxHeight: 400, overflowY: 'scroll'}}>
                  <ReactJson src={inputs}/>
                </Stack>
              }
            />
          </>
        )}
        <Stack direction={'column'} spacing={1} sx={{pt: 2, flex: 1}}>
          {jobRunItem && jobRunItem.job_datasets && datasets && (
            <>
              {inputDatasets && inputDatasets.length > 0 && (
                <>
                  <Typography variant={'subtitle2'}>{t('Input datasets')}</Typography>
                  <DatasetsList
                    datasets={inputDatasets}
                    name={'inpDs'}
                    minHeight={150}
                    columns={[COLUMN_NAMES.name_and_version, COLUMN_NAMES.last_updated_on, COLUMN_NAMES.files]}
                    isDatasetsLoading
                  />
                </>
              )}

              {outputDatasets && outputDatasets.length > 0 && (
                <>
                  <Typography variant={'subtitle2'}>{t('Output datasets')}</Typography>
                  <DatasetsList
                    datasets={outputDatasets}
                    minHeight={150}
                    name={'outDs'}
                    isDatasetsLoading
                    columns={[COLUMN_NAMES.name_and_version, COLUMN_NAMES.last_updated_on, COLUMN_NAMES.files]}
                  />
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
      <Stack sx={{pt: 2}} direction="row" justifyContent="center" spacing={2}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          color="primary"
        >
          {t('Close')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default JobRunInfoPopup;

export type JobsHistoryItem = JobRun & {
  jobType?: ProtoJobType;
};
